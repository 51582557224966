import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { DataserviceService } from '../dataservice.service';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOptgroup, MatOption, MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { ICompany } from 'src/interfaces';

@Component({
  selector: 'app-concentratoraction',
  standalone: true,
  imports: [MatDialogContent, MatDialogActions, MatButton, MatFormField, MatLabel, MatSelect, FormsModule, MatOption],
  templateUrl: './concentratoraction.component.html',
  styleUrl: './concentratoraction.component.scss'
})
export class ConcentratoractionComponent {

  ids: number[];
  currentCompany: ICompany;
  currentStatus = 3;
  constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<ConcentratoractionComponent>, @Inject(MAT_DIALOG_DATA) public data: number[]) {
    this.ids = data;
  }

  doLabel() {
    this.dataservice.postCreateConcentratorLabel(this.ids);
    this.dialogRef.close();
  }

  doCompany() {
    this.dataservice.setCompany(this.ids, this.currentCompany.companyId).subscribe(() => {
      this.dataservice.toastr.success('Operazione eseguita');
      this.dialogRef.close();

    }, () => {
      this.dataservice.toastr.error('Errore durante l\'esecuzione');
      this.dialogRef.close();

    });
  }

  doPeriod(period: number) {

    this.dataservice.setPeriod(this.ids, period).subscribe(() => {
      this.dataservice.toastr.success('Operazione eseguita');
      this.dialogRef.close();

    }, () => {
      this.dataservice.toastr.error('Errore durante l\'esecuzione');
      this.dialogRef.close();

    });
  }

  doNewStatus(status: number) {

    this.dataservice.setConcentratorStatus(this.ids, status).subscribe(() => {
      this.dataservice.toastr.success('Operazione eseguita');
      this.dialogRef.close();

    }, () => {
      this.dataservice.toastr.error('Errore durante l\'esecuzione');
      this.dialogRef.close();

    });
  }

  close() {
    this.dialogRef.close();
  }
}
