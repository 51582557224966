<h1 mat-dialog-title>Applica azioni a {{ids.length}} Concentratori</h1>
<mat-dialog-content>
    <div class="actions">
        <div class="actions__item">
            <button mat-raised-button (click)="doLabel()">Crea etichette</button>
        </div>
        <div class="actions__item">
            <button mat-raised-button (click)="doNewStatus(currentStatus)" color="warn">Assegna stato</button>&nbsp;
            <mat-form-field class="field">
                <mat-label>Stato</mat-label>
                <mat-select [(ngModel)]="currentStatus">
                    
                    <mat-option [value]="0">INSTALLATO</mat-option>
                    <mat-option [value]="1">ARCHIVIATO</mat-option>
                    <mat-option [value]="2">COLLAUDO</mat-option>
                    <mat-option [value]="3">MAGAZZINO</mat-option>
                    <mat-option [value]="4">CONSEGNATO</mat-option>
                    
                </mat-select>

            </mat-form-field>

        </div>
        <div class="actions__item">
            <button mat-raised-button (click)="doPeriod(2)" color="primary">VELOCE</button>
            <button mat-raised-button (click)="doPeriod(3)" color="primary">GIORNALIERO</button>
            <button mat-raised-button (click)="doPeriod(4)" color="primary">SETTIMANALE</button>
        </div>
        <div class="actions__item">
            <button mat-raised-button (click)="doCompany()" color="primary"
                [disabled]="currentCompany===undefined">Assegna a operatore</button>
                &nbsp;
            <mat-form-field class="field">
                <mat-label>Gestore</mat-label>
                <mat-select [(ngModel)]="currentCompany" [compareWith]="dataservice.compareByCompanyId">
                    @for (company of dataservice.companies; track $index) {
                    <mat-option [value]="company">{{ company.name
                        }}</mat-option>
                    }
                </mat-select>

            </mat-form-field>

        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button (click)="close()">Chiudi</button>
</mat-dialog-actions>